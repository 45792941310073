import React, { useState } from "react"
import styled from "styled-components"
import HalfStyled from "../styles/half/halfStyles"
import ContactStyled from "../styles/half/halfFormStyles"
import TitleStyled from "../styles/titleStyles"
import { useForm } from "react-hook-form"
import ButtonStyled from "../styles/ButtonStyles"
import myInitObject from "../../ultis/variable"

const MoreTitleStyled = styled(TitleStyled)`
  h2::after {
    content: none;
  }
`

const SubtitleStyled = styled.p`
  text-align: center;
  color: #fff;
  margin-top: 0;
`

const HalfForm = ({ form, position }) => {
  const { handleSubmit, register, errors } = useForm()
  const [sendingPass, setsendingPass] = useState(false)
  const onSubmit = (values, e) => {
    const formData = new FormData()
    formData.append("formName", "SingleCaseStudy Form")
    formData.append("name", values.name)
    formData.append("email", values.email)
    formData.append("phone", values.phone)
    formData.append("checkboxOne", values.RODO)

    if (typeof window !== "undefined") {
      let message = `New message from AudienceNetwork Contact Form (${window.location.href})`
      formData.append("message", message)
    }

    return fetch(myInitObject.cockpitUrl + "contactForm/", {
      method: "post",
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        setsendingPass(true)
        e.target.reset()
      }
    })
  }

  // emailError
  // nameError
  // phoneError
  // thx
  return (
    <HalfStyled backgroundColor="#000">
      <ContactStyled>
        <div className="half__wrapper">
          <div
            className={`half__content ${
              position === "left"
                ? "half__content--left"
                : "half__content--right"
            }`}
          >
            <MoreTitleStyled textAlign="center" color="#fff">
              <h2 dangerouslySetInnerHTML={{ __html: form.heading }}></h2>
            </MoreTitleStyled>
            <SubtitleStyled
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: form.subheading }}
            ></SubtitleStyled>

            <form
              className="HalfContact__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="HalfContact__form__row HalfContact__form__row--single">
                <input
                  placeholder={form.placeholderName}
                  name="name"
                  type="text"
                  ref={register({
                    required: true,
                  })}
                />
                <span>{errors.name && form.nameError}</span>
              </div>
              <div className="HalfContact__form__row HalfContact__form__row--single">
                <input
                  placeholder={form.placeholderPhone}
                  name="phone"
                  type="tel"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: form.phoneError,
                    },
                  })}
                />
                <span>{errors.phone && form.phoneError}</span>
              </div>

              <div className="HalfContact__form__row HalfContact__form__row--single">
                <input
                  placeholder={form.placeholderEmail}
                  name="email"
                  type="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: form.emailError,
                    },
                  })}
                />
                <span>{errors.email && form.emailError}</span>
              </div>

              <div className="HalfContact__form__row HalfContact__form__row--single">
                <label>
                  <input
                    type="checkbox"
                    name="RODO"
                    value="true"
                    ref={register({
                      validate: value => value === "true",
                    })}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: form.checkboxText,
                    }}
                  ></span>
                </label>
                <span>{errors.RODO && form.checkboxError}</span>
              </div>
              <div className="HalfContact__form__row HalfContact__form__row--single">
                {sendingPass ? <p class="thx">{form.thx}</p> : ""}
              </div>
              <ButtonStyled dark={false}>
                <button type="submit">{form.buttonText}</button>
              </ButtonStyled>
            </form>
          </div>
        </div>
      </ContactStyled>
    </HalfStyled>
  )
}

export default HalfForm
