import styled from "styled-components"

const CaseSingleStyled = styled.div`
  #wroclaw_inf_3 {
    .half__wrapper--image {
      background-position: 30px;
      @media (max-width: 880px) {
        background-position: center;
      }
    }
  }
  .half__wrapper {
    border-top: none;
    border-bottom: none;
  }
  .hero__case__wrapper {
    padding-top: 45px;
  }
  .halfIcon {
    @media (max-width: 880px) {
      order: 1;
    }
    .half__wrapper--image {
      background-size: contain;
      @media (max-width: 1280px) {
        background-size: contain;
      }
      @media (max-width: 880px) {
        min-height: 390px;
      }
      @media (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .caseStudyList__img img {
    width: initial;
    max-width: 47vw;
    height: 100%;
    @media (max-width: 880px) {
      /* max-width: initial; */
      max-width: 550px;
    }
    @media (max-width: 600px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .back {
    padding-top: 75px;
    a {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 5px;
    }
  }
  .caseStudyList__img {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .caseStudyList__item {
    height: 34vw;
    overflow: hidden;
    position: relative;
    margin-bottom: 70px;
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 1080px) {
      height: initial;
      margin-bottom: 70px;
    }
    @media (max-width: 880px) {
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
`

export default CaseSingleStyled
