import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"
import TitleStyled from "./styles/titleStyles"
import FeedStyled from "./styles/FeedStyles"
import FeedBox from "./feedBox"
import uuid from "react-uuid"

const FeedLastCaseStudyEN = ({ heading, pastPost }) => {
  const data = useStaticQuery(graphql`
    query {
      swapi {
        caseStudyCollection(lang: "en", limit: 3) {
          feedTitle
          feedCategory
          feedDescription
          feedTextButton
          feedImage {
            path
          }
          slug
          _id
        }
      }
    }
  `)
  const { caseStudyCollection } = data.swapi
  const [selectedCategory, setSelectedCategory] = useState("")
  const [postList, setPostList] = useState([])
  let listCategory = []

  useEffect(() => {
    setPostList(caseStudyCollection)
  }, [])

  caseStudyCollection.map(category => {
    category.feedCategory.map(item => {
      if (!listCategory.includes(item.replace(/(^[\s]+|[\s]+$)/g, ""))) {
        listCategory.push(item.replace(/(^[\s]+|[\s]+$)/g, ""))
      }
    })
  })
  let i = 0
  return (
    <FeedStyled>
      <section className="feed">
        <div className="container">
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color="#000"
            lineColor="#000"
          >
            <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
          </TitleStyled>
        </div>
        <div className="container container__feed blog__feed--2 case__feed--2">
          {caseStudyCollection.length
            ? caseStudyCollection.map(item => {
                if (pastPost != item._id) {
                  i++
                  if (i > 2) {
                    return ""
                  }
                  return (
                    <div key={uuid()} className="feed__item">
                      <FeedBox
                        img={myInitObject.cockpitUrl + item.feedImage.path}
                        title={item.feedTitle}
                        descirption={item.feedDescription}
                        link={"/en/casestudies/" + item.slug}
                        linkPostion="bottom"
                        // linkText={item.feedTextButton}
                        titleSize="30"
                        linkText={item.feedTextButton}
                      />
                    </div>
                  )
                } else {
                  // i++
                  return ""
                }
              })
            : ""}
        </div>
      </section>
    </FeedStyled>
  )
}

export default FeedLastCaseStudyEN
