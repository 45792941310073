import styled from "styled-components"
import arrow from "../../../../static/contact_arrow.svg"
import checkbox from "../../../../static/contact_checkbox.svg"

const ContactStyled = styled.div`
  @media (max-width: 880px) {
    h2 {
      text-align: center;
      margin-bottom: 40px;
      &:after {
        content: "";
        margin: 25px auto 0;
      }
    }
    .half__wrapper {
      padding: 60px 0;
    }
  }
h2 {
  font-size: 28px;
}
  .half__wrapper {
    padding: 85px 0;
  }
  .HalfContact__form {
    div[class*="ButtonStyles"] {
      padding-top: 15px;
    }
    
    .fileName {
      width: 100%;
      color: #fff;
    }
    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
      @media (max-width: 880px) {
        flex-wrap: wrap;
      }
      &--single {
        flex-direction: column;
      }
      textarea,
      input[type="email"],
      input[type="tel"],
      input[type="text"] {
        display: block;
        width: 100%;
        padding: 12px 14px 12px 26px;
        font-weight: 300;
        font-size: 16px;
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #848484;
        margin-bottom: 5px;
        color: #fff;
       
        &::placeholder {
          color: #848484;
        }
        &:focus {
          margin-bottom: 4px;
          border-bottom: 2px solid #848484;
        }
      }
      textarea {
        height: 140px;
        font-family: "Helvetica Neue", sans-serif;
      }
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }
      input[type="checkbox"] ~ span {
        color: #fff;
        font-size: 10px;
        display: block;
        padding-top: 4px;
        padding-left: 20px;
        line-height: 1.4;
      }
      input[type="checkbox"] ~ span:first-of-type:before {
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        display: block;
        position: absolute;
        top: 8px;
        left: 0;
        box-sizing: border-box;
        line-height: 0.5;
        @media (max-width: 600px) {
          width: 16px;
          height: 16px;
        }
      }
      input[type="checkbox"]:checked ~ span:first-of-type:before {
        /* content: ""; */
        /* color: #fff; */
        background: url('${checkbox}'), #000;
        background-position:center;
        background-repeat:no-repeat;
      }
      span {
        color: red;
        font-size: 12px;
        display: block;
        padding-top: 6px;
        padding-bottom: 2px;
      }
      .thx {
        color: #fff;
        /* font-size: 12px; */
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      label {
        color: #fff;
        font-size: 9px;
        line-height: 11px;
        position: relative;
      }
    }
    &__col {
      width: calc(50% - 6px);
      @media (max-width: 880px) {
        width: 100%;
      }
    }
  }
  .contactData {
    display: flex;
    padding-bottom: 70px;
    @media (max-width: 880px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding-bottom: 50px;
    }
  }
  .contactMap {
    width: 50%;
    @media (max-width: 880px) {
      width: 100%;
    }
    .google-map {
      height: 100%;
      width: 516px;
      max-width: 100%;
      min-height: 550px;
      @media (max-width: 880px) {
        margin-top: 50px;
        min-height: 350px;
        width: calc(100% + 40px);
        margin-left: -20px;
        display: block;
        margin-right: -50px;
        max-width: inherit;
        & > div {
          min-height: 350px;
        }
      }
    }
  }
  .contactAddress {
    width: 50%;
    padding-top: 40px;
    padding-left: 40px;
    @media (max-width: 1080px) {
      padding-left: 60px;
    }
    @media (max-width: 880px) {
      padding-left: 0;
      width: 100%;
      text-align: center;
      padding-top: 0;
      h2 {
        text-align: center;
        &:after {
          margin: 25px auto 0;
        }
      }
    }
    div:nth-of-type(2) {
      padding-top: 40px;
      @media (max-width: 880px) {
        padding-top: 0px;
      }
    }
    p {
      margin: 0;
      padding-bottom: 30px;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  .contactPages {
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 880px) {
      flex-wrap: wrap;
      padding-bottom: 0px;
    }
    &__col {
      position: relative;
      width: calc(33.33% - 23.33px);
      display: flex;
      @media (max-width: 880px) {
      width: calc(100%);
    }
      & > div {
        padding: 40px 20px 40px 40px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
        transition: 0.3s all;
        position: relative;
        z-index: 2;
        background: #fff;
        &:hover {
          transform: scale(1.05);
        }
        @media (max-width: 880px) {
          width: 100%;
          margin-bottom: 80px;
          padding: 30px 20px 30px 20px;
        }
        & > div {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: #b9b9b9;
        top: -30px;
        @media (max-width: 880px) {
          /* content: unset; */
          height: 80px;
          bottom: 0px;
          top: initial;
        }
      }
      &:last-of-type {
        @media (max-width: 880px) {
          &:after {
            content: unset;
          }
        }
      }
    }
    &__description {
      font-size: 17px;
      font-weight: 300;
      line-height: 26px;
      & > * {
        margin: 0;
        padding-bottom: 25px;
      }
    }
    &__link {
      margin-top: auto;
      text-align: right;
      padding-right: 20px;
      &:after {
        content: "";
        display: block;
        background: url(${arrow});
        background-position: 0 50%;
        background-repeat: no-repeat;
        width: 72px;
        height: 12px;
        margin-left: auto;
        transition: 0.3s all;
      }
      &:hover:after {
        background-position: 20px 50%;
      }
    }
    &__img {
      text-align: center;
      position: relative;
      img {
        height: 40px;
        object-fit: none;
        max-width: 100%;
      }
      &:after {
        content: "";
        display: block;
        width: 65px;
        margin: 40px auto 30px;
        height: 1px;
        background: #000;
        position: relative;
        z-index: 20;
      }
    }
  }
  .contactRegister {
    .container {
      max-width: 830px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 60px;
      @media (max-width: 1080px) {
        max-width: 800px;
      }
      @media (max-width: 880px) {
        max-width: 600px;
        padding: 0 40px;
      }
      @media (max-width: 600px) {
        padding: 0 20px;
      }
    }
    &__left {
      width: 240px;
      @media (max-width: 880px) {
        width: 100%;
        text-align: center;
      }
    }
    &__right {
      width: 460px;
      @media (max-width: 880px) {
        width: 100%;
        text-align: center;
      }
    }
  }
  .contactCloudTechnologies {
    padding-bottom: 80px;
    position: relative;
      @media (max-width: 880px) {
        padding-top:30px
      }
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 50px;
      background: #b9b9b9;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
      @media (max-width: 880px) {
        /* content: unset; */
        height: 80px;
        bottom: 0px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% - calc(33.33% - 23.33px));
      height: 1px;
      background: #b9b9b9;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
      @media (max-width: 880px) {
        content: unset;
      }
    }
    &__wrapper {
      padding: 40px 40px 40px 40px;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
      transition: 0.3s all;
      position: relative;
      z-index: 2;
      background: #fff;
      &:hover {
        transform: scale(1.05);
      }
      @media (max-width: 880px) {
        padding: 30px 20px 30px 20px;
      }
    }
    &__image {
      text-align: center;
      padding-bottom: 25px;
      img {
        max-width: 100%;
      }
    }
    &__description {
      font-size: 17px;
      font-weight: 300;
      line-height: 26px;
      max-width: 670px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: 880px) {
        text-align: left;
      }
      & > * {
        margin: 0;
        padding-bottom: 0px;
        @media (max-width: 880px) {
          padding-bottom: 25px;
        }
      }
    }
    &__link {
      margin-top: auto;
      text-align: right;
      /* padding-right: 20px; */
      &:after {
        content: "";
        display: block;
        background: url(${arrow});
        background-position: 0 50%;
        background-repeat: no-repeat;
        width: 72px;
        height: 12px;
        margin-left: auto;
        transition: 0.3s all;
      }
      &:hover:after {
        background-position: 20px 50%;
      }
    }
  }
`

export default ContactStyled
